import React from 'react';

import Link from '../../../Components/UI/Link';

const French: React.FC = () => (
  <>
    <h1>Conditions d'utilisation de Cloudsign</h1>
    <p>
      Veuillez lire attentivement l'intégralité des informations qui suivent avant d'utiliser la fonctionnalité
      Cloudsign de Teamleader (ci-après : « Cloudsign »). Il est vivement recommandé de télécharger ces conditions
      d'utilisation pour vous y référer et les consulter par la suite.
    </p>
    <p>
      En utilisant Cloudsign, vous acceptez explicitement de signer et de recevoir des documents par voie électronique
      et vous reconnaissez explicitement que l'utilisation d'une signature électronique implique un consentement
      juridiquement valable.
    </p>
    <p>
      Cloudsign est entièrement conforme au{' '}
      <Link href="https://eur-lex.europa.eu/eli/reg/2014/910/oj">règlement européen eIDAS</Link>. Teamleader a pris
      toutes les mesures raisonnables pour éviter que le document envoyé ne soit modifié par la suite et pour pouvoir
      établir l'identité du signataire.
    </p>
    <h5>Intégrité</h5>
    <p>
      Toute modification d'un document envoyé via Cloudsign après signature par le destinataire entraîne la création
      d'un nouveau document sur lequel la signature électronique du destinataire n'est plus apposée. Cela permet de
      préserver l'intégrité du document.
    </p>
    <p>
      Nous vous conseillons d'effectuer une copie de sauvegarde de chaque document signé via Cloudsign lorsque ce
      document vous est envoyé pour la première fois, soit en l'imprimant sur papier, soit en effectuant une sauvegarde
      numérique.
    </p>
    <h5>Identité</h5>
    <p>
      Lorsqu'un document est signé via Cloudsign, Teamleader stocke le document signé avec un enregistrement de
      l'adresse IP du signataire et l'heure de la signature. Le seul but de cette intervention est d'établir l'identité
      du signataire. En outre, vous acceptez que votre adresse IP et l'heure de la signature soient visibles par la
      contrepartie sur le document signé.
    </p>
    <p>
      Vous consentez à ce que Teamleader procède à ce traitement de données à caractère personnel afin d'intégrer les
      garanties nécessaires dans notre fonctionnalité de passation de contrats électroniques.
    </p>
    <p>
      Les documents signés par le biais de Cloudsign, y compris l'adresse IP et l'heure de la signature, sont conservés
      pendant toute la durée de la relation contractuelle entre vous et Teamleader. Toutes vos données à caractère
      personnel sont supprimées par Teamleader dans les six mois suivant la désactivation du compte client.
    </p>
    <h5>Remarque 1</h5>
    <p>
      Vous conservez toujours la possibilité d'opter pour une autre méthode de conclusion de contrat, par exemple sur
      papier via une signature manuscrite. Même après avoir accepté les présentes conditions d'utilisation, vous êtes
      toujours libre de demander à l'expéditeur de ne plus présenter de futurs documents pour signature électronique, en
      particulier lorsque vous concluez un contrat avec l'autre partie en qualité de consommateur.
    </p>
    <h5>Remarque 2</h5>
    <p>
      Outre la conservation en toute sécurité du document signé, il est fortement recommandé de disposer d'une
      correspondance écrite suffisante (par exemple par courrier électronique) avec la contrepartie. En cas de litige
      concernant le contrat, vous pourrez soumettre cette correspondance avec le document signé afin de lever toute
      ambiguïté.
    </p>
    <h5>Remarque 3</h5>
    <p>
      La signature électronique effectuée par le biais de Cloudsign sera très probablement considérée comme une «
      signature électronique simple » au sens du règlement eIDAS. Teamleader ne prétend en aucun cas offrir une
      fonctionnalité permettant à l'utilisateur d'apposer une signature électronique qualifiée ou avancée. Veuillez
      garder à l'esprit que, pour certains documents juridiques, la loi exige une signature qualifiée ou manuscrite.
    </p>
  </>
);

export default French;
