import { useMediaQuery } from 'react-responsive';

/**
 * For these devices the primary buttons (sign & feedback)
 * should be icon buttons instead.
 */
export const useIsBase = (): boolean => {
  return useMediaQuery({
    query: '(max-width: 480px)',
  });
};

/**
 * For these devices the navigation should be horizontal
 * and sidebars should take up the whole screen.
 */
export const useIsSmall = (): boolean => {
  return useMediaQuery({
    query: '(max-width: 768px)',
  });
};

/**
 * For these devices we can start showing the last update date.
 */
export const useIsMedium = (): boolean => {
  return useMediaQuery({
    query: '(min-width: 1024px)',
  });
};

/**
 * For these devices all buttons and menus can be full width.
 */
export const useIsLarge = (): boolean => {
  return useMediaQuery({
    query: '(min-width: 1194px)',
  });
};

/**
 * For these devices the branding can be shown right of the pdf.
 */
export const useHasSpaceForBranding = (): boolean => {
  return useMediaQuery({
    query: '(min-width: 1340px)',
  });
};

/**
 * For these devices the sidebar can be wider.
 */
export const useIsExtraLarge = (): boolean => {
  return useMediaQuery({
    query: '(min-width: 1560px)',
  });
};

/**
 * For these devices the sidebar can be the widest.
 */
export const useIsHuge = (): boolean => {
  return useMediaQuery({
    query: '(min-width: 1920px)',
  });
};

/**
 * We could also include (pointer: coarse) but that would exclude
 * touchscreens with a stylus.
 */
export const useIsTouchscreen = (): boolean => {
  return useMediaQuery({
    query: '(hover: none)',
  });
};
