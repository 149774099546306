import cx from 'classnames';
import React from 'react';

import * as styles from './styles.css';
import Box, { BoxProps } from '../Box';

interface SpinnerProps extends BoxProps {
  inverse?: boolean;
  size?: 'small' | 'medium';
}

const Spinner: React.FC<SpinnerProps> = ({ className, inverse, size = 'medium', ...rest }) => {
  const classNames = cx(
    styles['loading-spinner'],
    {
      [styles['is-inverse']]: inverse,
      [styles['loading-spinner-small']]: size === 'small',
    },
    className,
  );

  return <Box className={classNames} {...rest} />;
};

export default Spinner;
