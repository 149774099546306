import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

export enum FEATURE_FLAGS {
  SHOW_TOTAL_AMOUNTS = 'deals.show-total-amounts',
}

interface FlagResult<T> {
  loading: boolean;
  variation?: T;
}

const useFeatureFlag = <T = boolean>(flag: FEATURE_FLAGS, defaultValue?: T): FlagResult<T> => {
  const client = useLDClient();
  const [result, setResult] = useState<FlagResult<T>>({ loading: true, variation: undefined });

  useEffect(() => {
    if (__DEV__) {
      const flags = require('../../../../feature-flags.json'); // eslint-disable-line @typescript-eslint/no-var-requires, import/no-unresolved
      setResult({ loading: false, variation: flags[flag] ?? defaultValue });
      return;
    }

    if (__TEST__) {
      const flags = sessionStorage.getItem('x-tl-feature-flags');
      if (flags) {
        setResult({ loading: false, variation: JSON.parse(flags)[flag] ?? defaultValue });
        return;
      }
    }

    if (!client) {
      return;
    }

    let abort: () => void;
    (async () => {
      try {
        await Promise.race([
          new Promise<void>((resolve, reject) => {
            abort = reject;
          }),
          client.waitUntilReady(),
        ]);
      } catch (e) {
        return;
      }

      setResult({ loading: false, variation: client.variation(flag, defaultValue) });
    })();

    return () => {
      if (abort) {
        abort();
      }
    };
  }, [client, flag, defaultValue]);

  return result;
};

export default useFeatureFlag;
