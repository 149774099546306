const NAME = 'NetworkError';

export enum HTTP_STATUS_CODES {
  NOT_FOUND = 404,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
}

class NetworkError extends Error {
  name = NAME;
}

const isNetworkError = (error?: Error | null): error is NetworkError => {
  return error?.name === NAME;
};

export { isNetworkError };
export default NetworkError;
