import cx from 'classnames';
import React from 'react';

import ErrorBox from './ErrorBox';
import * as styles from './styles.css';
import { useIsSmall } from '../../Context/Hooks/mediaQueries';
import Box from '../Box';

export enum REASONS {
  GENERIC = 'generic',
  NETWORK = 'network',
}

interface ErrorProps {
  language?: string | null;
  renderNavigation?: boolean;
  reason?: REASONS;
}

const ErrorFallback: React.FC<ErrorProps> = ({ language, renderNavigation = true, reason = REASONS.GENERIC }) => {
  const isMobile = useIsSmall();

  return (
    <Box className={cx(styles['wrapper'], { [styles['wrapper--mobile']]: isMobile })}>
      {renderNavigation && (
        <Box className={styles['navigation']}>
          <Box className={styles['logo']} />
        </Box>
      )}
      <Box display="flex" flex={1} flexDirection="column">
        {renderNavigation && <Box className={styles['header']} />}
        <ErrorBox language={language} reason={reason} />
      </Box>
    </Box>
  );
};

export default ErrorFallback;
