import React from 'react';

import Link from '../../../Components/UI/Link';

const Dutch: React.FC = () => (
  <>
    <h1>Cloudsign Gebruiksvoorwaarden</h1>
    <p>
      Gelieve de onderstaande informatie zorgvuldig door te nemen vooraleer u gebruik maakt van de
      Cloudsign-functionaliteit van Teamleader (hierna: "Cloudsign"). Het wordt ten zeerst aanbevolen dat u deze
      Gebruiksvoorwaarden downloadt zodat u deze later steeds ter beschikking hebt.
    </p>
    <p>
      Wanneer u gebruik maakt van Cloudsign gaat u uitdrukkelijk akkoord om om documenten elektronisch te ondertekenen
      en ontvangen en erkent u uitdrukkelijk dat het gebruik van een elektronische handtekening een rechtsgeldige
      overeenkomst tot stand doet komen.
    </p>
    <p>
      Cloudsign is volledig in overeenstemming met de Europese{' '}
      <Link href="https://eur-lex.europa.eu/legal-content/NL/TXT/HTML/?uri=CELEX:32014R0910&from=EN">
        eIDAS-verordening
      </Link>
      . Teamleader heeft alle redelijke maatregelen genomen om te voorkomen dat het verzonden document achteraf wordt
      gewijzigd en om de identiteit van de ondertekenaar te kunnen vaststellen.
    </p>
    <h5>Integriteit</h5>
    <p>
      Elke wijziging van een via Cloudsign verzonden document na ondertekening door de ontvanger leidt tot het genereren
      van een nieuw document waarop de elektronische handtekening van de ontvanger niet langer is aangebracht. Dit
      teneinde de integriteit van het document te bewaren.
    </p>
    <p>
      U wordt geadviseerd een back-up te maken van elk document dat via Cloudsign is ondertekend wanneer dergelijke
      documenten voor het eerst naar u worden verzonden, hetzij door het op papier af te drukken, hetzij door het
      digitaal op te slaan.
    </p>
    <h5>Identiteit</h5>
    <p>
      Wanneer een document via Cloudsign wordt ondertekend, slaat Teamleader het ondertekende document op, samen met de
      registratie van het IP-adres van de ondertekenaar en het tijdstip van ondertekening. Dit gebeurt uitsluitend om de
      identiteit van de ondertekenaar vast te stellen. Bovendien gaat u ermee akkoord dat uw IP-adres en het tijdstip
      van ondertekening zichtbaar worden gemaakt voor de tegenpartij op het ondertekende document.{' '}
    </p>
    <p>
      U gaat ermee akkoord dat deze verwerking van persoonsgegevens door Teamleader wordt gedaan om de noodzakelijke
      waarborgen in onze elektronische contracteringsfunctionaliteit te kunnen voorzien.
    </p>
    <p>
      De via Cloudsign ondertekende documenten, inclusief het IP-adres en het tijdstip van ondertekening, worden
      opgeslagen zolang de contractuele relatie tussen u en Teamleader blijft bestaan. Al uw persoonsgegevens worden
      door Teamleader binnen zes maanden na het deactiveren van het klantaccount verwijderd.
    </p>
    <h5>Opmerking 1</h5>
    <p>
      U hebt te allen tijde de mogelijkheid om te kiezen voor een andere manier van contractafsluiting, bijvoorbeeld in
      papieren vorm via handgeschreven ondertekening. Ook na akkoord te zijn gegaan met deze Gebruiksvoorwaarden staat
      het u vrij de afzender te verzoeken om in de toekomst geen documenten meer voor elektronische ondertekening voor
      te leggen, met name wanneer u in de hoedanigheid van consument een contract met de andere partij sluit.
    </p>
    <h5>Opmerking 2</h5>
    <p>
      Naast het veilig opslaan van het ondertekende document, is het sterk aanbevolen om voldoende schriftelijke
      correspondentie (bijv. per e-mail) te hebben met de wederpartij. Indien er een geschil is over de overeenkomst,
      kunt u deze correspondentie samen met het ondertekende document voorleggen om eventuele onduidelijkheden op te
      helderen.
    </p>
    <h5>Opmerking 3</h5>
    <p>
      De elektronische handtekening die via Cloudsign wordt geplaatst, zal hoogstwaarschijnlijk worden beschouwd als een
      "eenvoudige elektronische handtekening" in de zin van de eIDAS-verordening. Teamleader beweert geenszins een
      functionaliteit aan te bieden waarmee de gebruiker een gekwalificeerde of geavanceerde elektronische handtekening
      kan plaatsen. Hou er rekening mee dat de wet voor sommige juridische documenten een gekwalificeerde of
      handgeschreven handtekening vereist.
    </p>
  </>
);

export default Dutch;
