import {
  init as sentryInit,
  setTags,
  withScope,
  Scope,
  captureException as sentryCaptureException,
} from '@sentry/browser';
import { Primitive } from '@sentry/types';

export const init = (tags: Record<string, Primitive>): void => {
  sentryInit({
    dsn: __CONFIG__.SENTRY_DSN,
    environment: __ENV__,
    enabled: !__DEV__,
    ignoreErrors: ['Non-Error promise rejection captured', "'Promise' is undefined"],
  });

  setTags({ ...tags });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const captureException = (error: unknown, errorInfo?: Record<string, unknown>): void => {
  try {
    // Manually throw an error to ensure we have a stacktrace
    if (!error || !/\d/.exec((error as Error).stack!)) throw Error((error as Error)?.message);
    throw error;
  } catch (error) {
    if (__DEV__) {
      console.error('[Sentry]', error, errorInfo);
      return;
    }

    withScope((scope: Scope) => {
      if (errorInfo) {
        scope.setExtras(errorInfo);
      }
      sentryCaptureException(error);
    });
  }
};
