import React from 'react';

import * as styles from './styles.css';
import Error from '../../../Svg/120x120_error.svg';
import NoConnection from '../../../Svg/120x120_noConnection.svg';
import Box from '../Box';
import Button from '../Button';

export enum REASONS {
  GENERIC = 'generic',
  NETWORK = 'network',
}

interface Text {
  title: string;
  action: string;
}

const TEXT: Record<string, Record<REASONS, Text> & { reload: string }> = {
  en: {
    reload: 'Reload page',
    generic: {
      title: 'Sorry, an unexpected error occurred',
      action: 'Please try again or reload the page.',
    },
    network: {
      title: 'No internet connection',
      action: 'Please check your internet connection and try reloading this page again.',
    },
  },
  nl: {
    reload: 'Pagina vernieuwen',
    generic: {
      title: 'Sorry, er is een onverwachte fout opgetreden',
      action: 'Gelieve opnieuw te proberen of de pagina te vernieuwen.',
    },
    network: {
      title: 'Geen internetverbinding',
      action: 'Gelieve je internetverbinding te controleren en deze pagina te vernieuwen.',
    },
  },
  fr: {
    reload: 'Recharger la page',
    generic: {
      title: 'Désolé, une erreur s’est produite.',
      action: 'Veuillez réessayer et recharger la page.',
    },
    network: {
      title: 'Pas de connexion internet',
      action: 'Veuillez vérifier votre connexion internet et essayez à nouveau de charger la page.',
    },
  },
  de: {
    reload: 'Seite neu laden',
    generic: {
      title: 'Entschuldigung, es ist ein unerwarteter Fehler aufgetreten',
      action: 'Bitte versuchen Sie es erneut oder laden Sie die Seite neu.',
    },
    network: {
      title: 'Keine Internetverbindung',
      action: 'Bitte kontrollieren Sie Ihre Internetverbindung oder laden Sie diese Seite neu.',
    },
  },
  es: {
    reload: 'Recargar la página',
    generic: {
      title: 'Lo sentimos, se ha producido un error inesperado',
      action: 'Por favor, inténtalo de nuevo o recarga la página.',
    },
    network: {
      title: 'No hay conexión a Internet',
      action: 'Por favor, comprueba tu conexión a Internet e intenta recargar esta página de nuevo.',
    },
  },
  it: {
    reload: 'Ricarica la pagina',
    generic: {
      title: 'Spiacenti, si è verificato un errore inaspettato',
      action: 'Per favore riprova o ricarica la pagina.',
    },
    network: {
      title: 'Nessuna connessione internet',
      action: 'Per favore controlla la tua connessione internet e prova a ricaricare di nuovo questa pagina.',
    },
  },
};

const SVGS: Record<REASONS, React.ComponentType<any>> = {
  generic: Error,
  network: NoConnection,
};

interface ErrorBoxProps {
  language?: string | null;
  reason?: REASONS;
}

const ErrorBox: React.FC<ErrorBoxProps> = ({ language, reason = REASONS.GENERIC }) => {
  const handleReload = () => {
    location.reload();
  };

  const text = TEXT[language!] || TEXT[navigator.language.split('-')[0]] || TEXT['en'];
  const Component = SVGS[reason];

  return (
    <Box className={styles['error']}>
      <Component className={styles['illustration']} />
      <h2 className={styles['title']}>{text[reason].title}</h2>
      <p className={styles['message']}>{text[reason].action}</p>
      <Button level="primary" marginTop={5} onClick={handleReload}>
        {text.reload}
      </Button>
    </Box>
  );
};

export default ErrorBox;
