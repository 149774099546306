import React from 'react';

import { getError, NetworkError } from './errors';

const MAX_TRIES = 3;

const lazyLoad = <T extends React.ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
): React.LazyExoticComponent<T> => {
  return React.lazy(async () => {
    for (let i = 0; i < MAX_TRIES - 1; i++) {
      try {
        return await factory();
      } catch (error) {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }

    try {
      return await factory();
    } catch (error) {
      throw new NetworkError(getError(error)?.message);
    }
  });
};

export default lazyLoad;
