import { getStatusCode, isNetworkError } from '.';
import { HTTP_STATUS_CODES } from './NetworkError';

export const isError = (error: unknown): error is Error => {
  return error instanceof Error;
};

export const getError = <T extends Error>(error: unknown): T | null => (isError(error) ? (error as T) : null);

/**
 * Throw unexpected errors to be handled by an error boundary.
 */
export const assertErrors = (...errors: unknown[]): unknown => {
  errors.forEach((error) => {
    if (isError(error) && (getStatusCode(error) >= HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR || isNetworkError(error))) {
      throw error;
    }
  });

  return errors.find((error) => !!error);
};
