import { translate } from '@teamleader/i18n';
import { DateTime, DateTimeUnit, Duration } from 'luxon';

const FULL_DUE_DATE_CUTOFF = 30;

export const formatDate = (date: DateTime, format: string): string => {
  return date.toFormat(format);
};

export const formatDateShort = (date: DateTime): string => formatDate(date, 'dd/MM/yyyy');

export const fromISO = (date: string): DateTime => DateTime.fromISO(date, { setZone: true });

export const apiFormatDate = (date: DateTime, dateFormat: string): string => {
  // Transform to javascript date format.
  dateFormat = dateFormat.replace('Y', 'yyyy');
  dateFormat = dateFormat.replace('d', 'dd');
  dateFormat = dateFormat.replace('m', 'MM');

  return formatDate(date, dateFormat);
};

export const today = () => DateTime.local().startOf('day');

export const isAfter = (date1: DateTime, date2 = DateTime.local(), unit: DateTimeUnit = 'day'): boolean => {
  return date1.startOf(unit) > date2.endOf(unit);
};

export const isBefore = (date1: DateTime, date2 = DateTime.local(), unit: DateTimeUnit = 'day'): boolean => {
  return date1.endOf(unit) < date2.startOf(unit);
};

export const getDifference = (date1: DateTime, date2: DateTime, unit: DateTimeUnit = 'day'): Duration => {
  return date1.diff(date2, unit);
};

export const formatDueDate = (
  dueDate: DateTime,
  dateFormat: string,
): { label: string; date: string; isOverDue: boolean } => {
  const today = DateTime.local().startOf('day');

  if (dueDate.diff(today, 'day').days <= -FULL_DUE_DATE_CUTOFF) {
    return {
      label: translate('overdueSince'),
      date: apiFormatDate(dueDate, dateFormat),
      isOverDue: true,
    };
  }

  if (dueDate.diff(today, 'day').days < 0) {
    return {
      label: translate('overdueFor'),
      date: translate('days', { amount: -dueDate.diff(today, 'day').days }),
      isOverDue: true,
    };
  }

  if (dueDate.equals(today)) {
    return {
      label: translate('due'),
      date: translate('date.today'),
      isOverDue: false,
    };
  }

  if (dueDate.diff(today, 'day').days < FULL_DUE_DATE_CUTOFF) {
    return {
      label: translate('dueIn'),
      date: translate('days', { amount: dueDate.diff(today, 'day').days }),
      isOverDue: false,
    };
  }

  return {
    label: translate('dueOn'),
    date: apiFormatDate(dueDate, dateFormat),
    isOverDue: false,
  };
};
