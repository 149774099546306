const NAME = 'HttpError';

class HttpError extends Error {
  public statusCode: number;
  public statusText: string;

  constructor(statusCode: number, statusText: string, message?: string) {
    if (message) {
      super(`HTTP Error: ${statusCode}: ${message}`);
    } else {
      super(`HTTP Error: ${statusCode} (${statusText})`);
    }

    this.name = NAME;
    this.statusCode = statusCode;
    this.statusText = statusText;
  }
}

const isHttpError = (error?: unknown | null): error is HttpError => {
  return (error as Error)?.name === NAME;
};

const getStatusCode = (error?: unknown | null): number => {
  if (isHttpError(error)) {
    return error.statusCode;
  }

  return 0;
};

export { isHttpError, getStatusCode };
export default HttpError;
