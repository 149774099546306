import jwtDecode from 'jwt-decode';
import { DateTime } from 'luxon';
import { createContext } from 'react';

import { fromISO } from './date';
import { captureException } from './sentry';
import { parse } from './uuid';

export enum MODES {
  PREVIEW = 'preview',
}

export enum TYPES {
  DEAL = 'deal',
  INVOICE = 'invoice',
  PROJECT = 'project',
}

export interface UrlData {
  ['account_id']?: string;
  ['deal_id']?: string;
  ['invoice_id']?: string;
  ['project_id']?: string;
  ss?: string;
  type?: TYPES;
  mode?: MODES;
  ['expires_at']?: string;
}

export interface DecodedUrlData {
  accountId?: string;
  dealId?: string;
  invoiceId?: string;
  invoiceUuid?: string;
  projectId?: string;
  type?: TYPES;
  mode?: MODES;
  expiresAt?: DateTime;
}

export const decodeUrl = (): DecodedUrlData => {
  try {
    const token = window.location.pathname.split('/').filter((part) => !!part)[0];
    const data = jwtDecode<UrlData>(token);

    return {
      accountId: data['account_id'] ? String(parse(data['account_id'])) : undefined,
      dealId: data['deal_id'] ? String(parse(data['deal_id'])) : undefined,
      invoiceId: data['invoice_id'] ? String(parse(data['invoice_id'])) : undefined,
      invoiceUuid: data['invoice_id'],
      projectId: data['project_id'],
      type: data.type,
      mode: data.mode,
      expiresAt: data['expires_at'] ? fromISO(data['expires_at']) : undefined,
    };
  } catch (error) {
    captureException(error);
    return {};
  }
};

export const UrlContext = createContext<DecodedUrlData>({});
