// extracted by mini-css-extract-plugin
var _1 = "hB9r9";
var _2 = "A3dTB";
var _3 = "up_10";
var _4 = "tZds0";
var _5 = "URXnQ";
var _6 = "KBYXM";
var _7 = "ACrXj";
var _8 = "nTJ46";
var _9 = "GrMLa";
var _a = "pSKPx";
export { _1 as "contact", _2 as "error", _3 as "header", _4 as "illustration", _5 as "logo", _6 as "message", _7 as "navigation", _8 as "title", _9 as "wrapper", _a as "wrapper--mobile" }
