import React, { Component } from 'react';

import { isNetworkError } from '../../../Utils/errors/NetworkError';
import { captureException } from '../../../Utils/sentry';
import { REASONS } from '../../UI/ErrorFallback/ErrorFallback';
interface ErrorBoundaryProps {
  fallback?: React.ReactElement;
  children?: React.ReactNode;
}

interface ErrorBoundaryState {
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    error: null,
  };

  static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    captureException(error, { ...errorInfo });
  }

  render(): React.ReactNode {
    if (this.state.error) {
      if (!this.props.fallback) {
        return null;
      }

      let reason = REASONS.GENERIC;
      if (isNetworkError(this.state.error)) {
        reason = REASONS.NETWORK;
      }

      return React.cloneElement(this.props.fallback, { reason });
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
