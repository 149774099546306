import React from 'react';

import Link from '../../../Components/UI/Link';

const Spanish: React.FC = () => (
  <>
    <h1>Condiciones de uso de Cloudsign</h1>
    <p>
      Por favor, lea detenida y exhaustivamente la información que sigue antes de utilizar la funcionalidad Cloudsign de
      Teamleader (en adelante, “Cloudsign”). Recomendamos encarecidamente que descargue las presentes Condiciones de uso
      para poder consultarlas más adelante.
    </p>
    <p>
      Al hacer uso de Cloudsign, usted acepta explícitamente firmar y recibir documentos de forma digital y reconoce
      expresamente que el uso de una firma electrónica dará lugar a un acuerdo con validez jurídica.
    </p>
    <p>
      Cloudsign cumple plenamente con el{' '}
      <Link href="https://eur-lex.europa.eu/eli/reg/2014/910/oj">reglamento eIDAS</Link> de la UE. Teamleader ha tomado
      todas las medidas razonables para evitar que el documento enviado se modifique posteriormente y para poder
      establecer la identidad del firmante.
    </p>
    <h5>Integridad</h5>
    <p>
      Cualquier modificación de un documento enviado a través de Cloudsign tras la firma por parte del destinatario dará
      lugar a la generación de un nuevo documento en el que ya no aparece la firma electrónica del destinatario. Esto se
      hace con el fin de preservar la integridad del documento.
    </p>
    <p>
      Le recomendamos que haga una copia de seguridad de cada documento firmado a través de Cloudsign cuando lo reciba
      por primera vez, ya sea imprimiéndolo sobre papel o guardándolo digitalmente.
    </p>
    <h5>Identidad</h5>
    <p>
      Al firmar un documento a través de Cloudsign, Teamleader guardará el documento firmado junto con un registro de la
      dirección IP del firmante y la hora de la firma. Esto se hace únicamente con el fin de establecer la identidad del
      firmante. Además, usted acepta que su dirección IP y la hora de la firma se revelarán a la otra parte en el
      documento firmado.
    </p>
    <p>
      Usted consiente que Teamleader tratará dichos datos personales con el fin de incorporar las salvaguardias
      necesarias en su funcionalidad para la elaboración de contratos electrónicos.
    </p>
    <p>
      Los documentos firmados a través de Cloudsign, incluidas la dirección IP y la hora de la firma, se conservarán
      mientras la relación contractual entre usted y Teamleader persista. Teamleader eliminará todos sus datos
      personales en el plazo de seis meses siguientes a la desactivación de la cuenta del cliente.
    </p>
    <h5>Nota 1</h5>
    <p>
      Siempre tiene la posibilidad de optar por otro método para formalizar sus contratos, como en papel impreso con su
      firma manuscrita, por ejemplo. Incluso después de haber aceptado las presentes Condiciones de uso, seguirá
      disfrutando de la opción de solicitar al remitente que deje de presentarle documentos que precisen de firma
      electrónica. Esto es muy útil si celebra un contrato en calidad de consumidor.
    </p>
    <h5>Nota 2</h5>
    <p>
      Además de almacenar seguramente el documento firmado, le recomendamos encarecidamente que guarde suficiente
      correspondencia escrita (como correos electrónicos) con la otra parte. En caso de una disputa sobre el contrato,
      podrá enviar esta correspondencia junto con el documento firmado para aclarar cualquier ambigüedad.
    </p>
    <h5>Nota 3</h5>
    <p>
      Lo probable es que la firma electrónica introducida a través de Cloudsign se considere como una “firma electrónica
      simple” en el sentido del reglamento eIDAS. Teamleader no pretende de ninguna manera que su funcionalidad sirva
      para introducir firmas electrónicas cualificadas o avanzadas. Tenga en cuenta que, para algunos documentos
      legales, la ley impone una firma cualificada o manuscrita.
    </p>
  </>
);

export default Spanish;
