import React from 'react';

import Link from '../../../Components/UI/Link';

const Italian: React.FC = () => (
  <>
    <h1>Condizioni di utilizzo di Cloudsign</h1>
    <p>
      Leggere attentamente le informazioni riportate sotto prima di utilizzare la funzionalità Cloudsign di Teamleader
      (di seguito denominata “Cloudsign”). Si raccomanda caldamente di scaricare le presenti Condizioni d'uso a scopo di
      riferimento futuro e accesso.
    </p>
    <p>
      Utilizzando Cloudsign, l'utente accetta esplicitamente di sottoscrivere e ricevere documenti per via elettronica,
      oltre a riconoscere in modo inequivocabile che l'uso di una firma elettronica produce un accordo legalmente
      valido.
    </p>
    <p>
      Cloudsign è completamente allineato alla{' '}
      <Link href="https://eur-lex.europa.eu/eli/reg/2014/910/oj">regolamentazione europea eIDAS</Link>. Teamleader ha
      adottato tutte le misure ragionevoli per evitare la successiva modifica del documento inviato e stabilire
      l'identità del firmatario.
    </p>
    <h5>Integrità</h5>
    <p>
      Qualsiasi modifica a un documento inviato tramite Cloudsign dopo la firma del destinatario comporterà la
      generazione di un nuovo documento sul quale non sarà più apposta la firma elettronica del destinatario, al fine di
      preservare l'integrità del documento.
    </p>
    <p>
      Quando i documenti sono inviati per la prima volta al destinatario, si consiglia di eseguire un back-up di ogni
      documento firmato tramite Cloudsign, stampandolo su carta o salvandolo in formato digitale.
    </p>
    <h5>Identità</h5>
    <p>
      Quando un documento viene firmato tramite Cloudsign, Teamleader memorizza il documento firmato insieme a una
      registrazione dell'indirizzo IP del firmatario e dell'ora della firma, esclusivamente al fine di stabilire
      l'identità del firmatario. L’utente concorda altresì che il suo indirizzo IP e l'ora della firma siano resi
      visibili alla controparte sul documento firmato.{' '}
    </p>
    <p>
      L'utente accetta che tale trattamento dei dati personali da parte di Teamleader sia effettuato al fine di
      integrare le protezioni necessarie nella funzionalità di contrattazione elettronica.
    </p>
    <p>
      I documenti firmati tramite Cloudsign, inclusi l'indirizzo IP e l'ora della firma, saranno conservati per l’intero
      periodo di validità del rapporto contrattuale tra l'utente e Teamleader. I dati personali dell’utente saranno
      rimossi da Teamleader entro sei mesi dalla disattivazione dell'account cliente.
    </p>
    <h5>Nota 1</h5>
    <p>
      È possibile optare in qualsiasi momento per un altro metodo di stipula di un contratto, ad esempio in formato
      cartaceo tramite firma autografa. Anche dopo l’accettazione delle presenti Condizioni d'uso, l'utente è comunque
      libero di richiedere al mittente di non presentare ulteriori documenti per la firma elettronica, soprattutto alla
      stipula di un contratto con la controparte in qualità di consumatore.
    </p>
    <h5>Nota 2</h5>
    <p>
      Oltre a conservare in modo sicuro il documento firmato, si raccomanda caldamente di intrattenere una
      corrispondenza scritta (ad es. via e-mail) adeguata con la controparte. In caso di controversia sul contratto,
      l’utente può presentare tale corrispondenza insieme al documento firmato per chiarire eventuali incongruenze.
    </p>
    <h5>Nota 3</h5>
    <p>
      La firma elettronica apposta tramite Cloudsign sarà verosimilmente considerata una "firma elettronica semplice" ai
      sensi della regolamentazione eIDAS. Teamleader non intende in alcun modo offrire una funzionalità con cui l'utente
      possa apporre una firma elettronica qualificata o avanzata. Si consideri che, per alcuni documenti legali, la
      legge richiede una firma qualificata o autografa.
    </p>
  </>
);

export default Italian;
