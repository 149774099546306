import { TYPES } from './jwt';

export enum SOURCES {
  CLOUDSIGN = 'cloudsign',
  INVOICECLOUD = 'invoicecloud',
  PROJECTCLOUD = 'projectcloud',
}

const TYPES_TO_SOURCES_MAP: Record<TYPES, SOURCES> = {
  [TYPES.DEAL]: SOURCES.CLOUDSIGN,
  [TYPES.INVOICE]: SOURCES.INVOICECLOUD,
  [TYPES.PROJECT]: SOURCES.PROJECTCLOUD,
};

let source = SOURCES.CLOUDSIGN;

export function init(type?: TYPES): void {
  if (type) {
    source = TYPES_TO_SOURCES_MAP[type];
  }

  if (__DEV__ || __TEST__) {
    return;
  }

  if (window.analytics) {
    window.analytics.load('UvGPEFH5RiFZ1EdUoqFapDD8HFMEHIO0');
    window.analytics.identify({
      screen: {
        width: window.screen.width,
        height: window.screen.height,
      },
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
    window.analytics.page();
  }
}

export function track(event: string, meta?: Record<string, any>): void {
  if (__DEV__ || __TEST__) {
    console.log('[tracked] ', event, { source, ...meta });
    return;
  }

  if (window.analytics) {
    window.analytics.track(event, { source, ...meta });
  }
}

export const EVENTS = {
  STARTED_REQUESTING: 'quotation.startedRequesting',
  STARTED_SIGNING: 'quotation.startedSigning',
  STARTED_ADDING_NAME: 'quotation.startedAddingName',
  CLICKED_TERMS_OF_SERVICE: 'quotation.clickedTermsOfService',
  CHANGED_SIGNING_STYLE: 'quotation.changedSigningStyle',
  STARTED_ADDING_SIGNATURE: 'quotation.startedAddingSignature',
  SIGNED: 'quotation.signed',
  RESET_SIGNING_STYLE: 'quotation.resetSigningStyle',
  CANCELED_SIGNING: 'quotation.cancelledSigning',
  DOWNLOADED: 'quotation.downloaded',
  STARTED_ADDING_FEEDBACK: 'quotation.startedAddingFeedback',
  CHANGED_FEEDBACK_SUBJECT: 'quotation.changedFeedbackSubject',
  CHANGED_FEEDBACK_CONTENT: 'quotation.changedFeedbackContent',
  SELECTED_EMAIL_OPTIN: 'quotation.selectedEmailOptIn',
  SELECTED_EMAIL_OPTOUT: 'quotation.selectedEmailOptOut',
  SENT_FEEDBACK: 'quotation.sentFeedback',
  CANCELLED_ADDING_FEEDBACK: 'quotation.cancelledAddingFeedback',
  SELECTED_DEAL: 'deal.selected',
  SELECTED_QUOTATION: 'quotation.selected',
  CLICKED_BRANDING: 'teamleaderBranding.clicked',
  SELECTED_INVOICE: 'invoice.selected',
  CLICKED_LOAD_MORE: 'invoice.clickedLoadMore',
  CLICKED_INVOICE_TAB: 'invoice.clickedInvoiceTab',
  CLICKED_CREDIT_NOTE_TAB: 'invoice.clickedCreditNoteTab',
  DOWNLOADED_INVOICE: 'invoice.downloaded',
  STARTED_ADDING_INVOICE_FEEDBACK: 'invoice.startedAddingFeedback',
  CANCELLED_ADDING_INVOICE_FEEDBACK: 'invoice.cancelledAddingFeedback',
  SENT_INVOICE_FEEDBACK: 'invoice.sentFeedback',
  STARTED_PAYING: 'invoice.startedPaying',
  CANCELLED_SELECTING_PAYMENT_METHOD: 'invoice.cancelledSelectingPaymentMethod',
  SELECTED_PAYMENT_METHOD: 'invoice.selectedPaymentMethod',
};
