// extracted by mini-css-extract-plugin
var _1 = "oo6_9";
var _2 = "m7oDt";
var _3 = "k7a8H";
var _4 = "oBqkp";
var _5 = "yyGjC";
var _6 = "Q0K2v";
var _7 = "qVhVZ";
var _8 = "VTJmb";
var _9 = "Z5LBm";
var _a = "gkbvR";
var _b = "ww03Y";
var _c = "zkUf8";
var _d = "qXsMt";
var _e = "lU5Ou";
var _f = "WEIUQ";
var _10 = "tCU0Z";
var _11 = "EUunT";
var _12 = "OznLN";
var _13 = "v76Ny";
var _14 = "syJZ0";
var _15 = "HUkya";
var _16 = "ELqhP";
var _17 = "NHj4S";
var _18 = "Bjf4X";
var _19 = "SPZmG";
var _1a = "Cry55";
var _1b = "uZnJk";
export { _1 as "app", _2 as "box-shadow-100", _3 as "box-shadow-200", _4 as "box-shadow-300", _5 as "box-shadow-400", _6 as "clearfix", _7 as "disable-user-select", _8 as "heading", _9 as "heading-1", _a as "heading-2", _b as "heading-3", _c as "heading-4", _d as "heading-5", _e as "monospaced", _f as "overflow-ellipsis", _10 as "reset", _11 as "reset-box-sizing", _12 as "reset-font-smoothing", _13 as "text", _14 as "text-body", _15 as "text-body-compact", _16 as "text-display", _17 as "text-small", _18 as "ui-text", _19 as "ui-text-body", _1a as "ui-text-display", _1b as "ui-text-small" }
