import cx from 'classnames';
import React, { ComponentType, forwardRef, ReactNode } from 'react';

import * as styles from './styles.css';

type SpacingNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export interface BoxProps {
  element?: string | ComponentType<any>;
  className?: string;
  children?: ReactNode;
  display?: 'inline' | 'inline-block' | 'block' | 'flex' | 'inline-flex' | 'none';
  flex?: string | number;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch';
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly';
  margin?: SpacingNumber;
  marginHorizontal?: SpacingNumber;
  marginVertical?: SpacingNumber;
  marginLeft?: SpacingNumber;
  marginRight?: SpacingNumber;
  marginTop?: SpacingNumber;
  marginBottom?: SpacingNumber;
  padding?: SpacingNumber;
  paddingHorizontal?: SpacingNumber;
  paddingVertical?: SpacingNumber;
  paddingLeft?: SpacingNumber;
  paddingRight?: SpacingNumber;
  paddingTop?: SpacingNumber;
  paddingBottom?: SpacingNumber;
  overflow?: 'auto' | 'hidden' | 'scroll' | 'visible';
  [key: string]: any;
}

const Box = forwardRef<HTMLElement, BoxProps>(
  (
    {
      element = 'div',
      className,
      display,
      flex,
      flexDirection,
      alignItems,
      justifyContent,
      margin,
      marginHorizontal = margin,
      marginVertical = margin,
      marginLeft = marginHorizontal,
      marginRight = marginHorizontal,
      marginTop = marginVertical,
      marginBottom = marginVertical,
      padding,
      paddingHorizontal = padding,
      paddingVertical = padding,
      paddingLeft = paddingHorizontal,
      paddingRight = paddingHorizontal,
      paddingTop = paddingVertical,
      paddingBottom = paddingVertical,
      overflow,
      style,
      ...rest
    },
    ref,
  ) => {
    const ElementToUse = element;

    const classNames = cx(
      styles['box'],
      {
        [styles[`display-${display || ''}`]]: display,
        [styles[`flex-direction-${flexDirection || ''}`]]: flexDirection,
        [styles[`align-items-${alignItems || ''}`]]: alignItems,
        [styles[`justify-content-${justifyContent || ''}`]]: justifyContent,
        [styles[`margin-bottom-${marginBottom || ''}`]]: marginBottom && marginBottom > 0,
        [styles[`margin-left-${marginLeft || ''}`]]: marginLeft && marginLeft > 0,
        [styles[`margin-right-${marginRight || ''}`]]: marginRight && marginRight > 0,
        [styles[`margin-top-${marginTop || ''}`]]: marginTop && marginTop > 0,
        [styles[`padding-bottom-${paddingBottom || ''}`]]: paddingBottom && paddingBottom > 0,
        [styles[`padding-left-${paddingLeft || ''}`]]: paddingLeft && paddingLeft > 0,
        [styles[`padding-right-${paddingRight || ''}`]]: paddingRight && paddingRight > 0,
        [styles[`padding-top-${paddingTop || ''}`]]: paddingTop && paddingTop > 0,
      },
      className,
    );

    const elementStyles = {
      ...(flex && { flex }),
      ...(overflow && { overflow }),
      ...style,
    };

    return <ElementToUse ref={ref} className={classNames} style={elementStyles} {...rest} />;
  },
);

export default Box as React.ComponentType<BoxProps>;
