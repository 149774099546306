import React from 'react';

import Link from '../../../Components/UI/Link';

const English: React.FC = () => (
  <>
    <h1>Cloudsign Terms of Use</h1>
    <p>
      Please read the information below carefully and thoroughly before making use of Teamleader’s Cloudsign
      functionality (hereafter: “Cloudsign”). It is highly recommended that you download these Terms of Use for your
      future reference and access.
    </p>
    <p>
      When making use of Cloudsign, you explicitly agree to sign and receive documents electronically and you explicitly
      acknowledge that the use of an electronic signature gives rise to a legally valid agreement.
    </p>
    <p>
      Cloudsign is fully in line with the European{' '}
      <Link href="https://eur-lex.europa.eu/eli/reg/2014/910/oj">eIDAS regulation</Link>. Teamleader has taken all
      reasonable measures to prevent the sent document from being modified afterwards and to be able to establish the
      identity of the signatory.
    </p>
    <h5>Integrity</h5>
    <p>
      Any modification to a document sent via Cloudsign after signature by the recipient shall result in the generation
      of a new document on which the electronic signature of the recipient is no longer affixed. This is done in order
      to preserve the integrity of the document.
    </p>
    <p>
      You are advised to make a back-up of each document signed through Cloudsign when such documents are first sent to
      you, either by printing it on paper or saving it digitally.
    </p>
    <h5>Identity</h5>
    <p>
      When a document is signed via Cloudsign, Teamleader stores the signed document together with a record of the
      signatory's IP address and the time of signature. This is done solely for the purposes of establishing the
      identity of the signatory. In addition, you agree that your IP address and time of signature will be made visible
      to the counterparty on the signed document.
    </p>
    <p>
      You agree that such processing of personal data by Teamleader is done to incorporate the necessary safeguards into
      our electronic contracting functionality.
    </p>
    <p>
      The documents signed through Cloudsign, including the IP address and time of signature, shall be stored for as
      long as the contractual relationship between you and Teamleader continues to exist. All your personal data is
      removed by Teamleader within six months after the deactivation of the customer account.
    </p>
    <h5>Note 1</h5>
    <p>
      You always have the possibility to opt for another method of contract conclusion, such as in paper format via
      handwritten signature. Even after having agreed to these Terms of Use you are still free to request the sender to
      stop presenting any future documents for electronic signature, especially when you enter into a contract with the
      other party in the capacity of consumer.
    </p>
    <h5>Note 2</h5>
    <p>
      In addition to safely storing the signed document, it is strongly recommended to have sufficient written
      correspondence (e.g. by email) with the counterparty. If there is a dispute about the contract, you can submit
      this correspondence together with the signed document to clarify any ambiguities.
    </p>
    <h5>Note 3</h5>
    <p>
      The electronic signature placed through Cloudsign will most likely be considered a 'simple electronic signature'
      in the sense of the eIDAS regulation. Teamleader in no way claims to offer a functionality with which the user can
      place a qualified or advanced electronic signature. Keep in mind that, for some legal documents, the law requires
      a qualified or handwritten signature.
    </p>
  </>
);

export default English;
