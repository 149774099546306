import React from 'react';

import Link from '../../../Components/UI/Link';

const German: React.FC = () => (
  <>
    <h1>Nutzungsbedingungen für Cloudsign</h1>
    <p>
      Bitte lesen Sie die unten stehenden Informationen sorgfältig und gründlich durch, bevor Sie die
      Cloudsign-Funktionalität von Teamleader nutzen (im Folgenden bezeichnet als: „Cloudsign“). Es wird dringend
      empfohlen, dass Sie diese Nutzungsbedingungen für Ihre zukünftige Bezugnahme und Ihren Zugang herunterladen.
    </p>
    <p>
      Wenn Sie Cloudsign verwenden, stimmen Sie ausdrücklich zu, Dokumente elektronisch zu signieren und zu empfangen,
      und Sie erkennen ausdrücklich an, dass die Verwendung einer elektronischen Signatur zu einer rechtsgültigen
      Vereinbarung führt.
    </p>
    <p>
      Cloudsign steht voll im Einklang mit der europäischen{' '}
      <Link href="https://eur-lex.europa.eu/eli/reg/2014/910/oj">eIDAS-Verordnung</Link>. Teamleader hat alle
      angemessenen Maßnahmen ergriffen, um zu verhindern, dass das gesendete Dokument nachträglich geändert wird, und um
      die Identität des Unterzeichners feststellen zu können.
    </p>
    <h5>Integrität</h5>
    <p>
      Jede Änderung an einem über Cloudsign versandten Dokument nach der Unterzeichnung durch den Empfänger führt zur
      Erzeugung eines neuen Dokuments, auf dem die elektronische Signatur des Empfängers nicht mehr angebracht ist. Dies
      geschieht, um die Integrität des Dokuments zu wahren.
    </p>
    <p>
      Wir empfehlen Ihnen, von jedem über Cloudsign signierten Dokument eine Sicherheitskopie anzufertigen, wenn Sie ein
      solches Dokument zum ersten Mal erhalten, indem Sie es entweder auf Papier ausdrucken oder digital speichern.
    </p>
    <h5>Identität</h5>
    <p>
      Wenn ein Dokument über Cloudsign signiert wird, speichert Teamleader das signierte Dokument zusammen mit einer
      Aufzeichnung der IP-Adresse des Unterzeichners und dem Zeitpunkt der Signatur. Dies geschieht ausschließlich zum
      Zwecke der Feststellung der Identität des Unterzeichners. Darüber hinaus erklären Sie sich damit einverstanden,
      dass Ihre IP-Adresse und der Zeitpunkt der Unterzeichnung für die Gegenpartei auf dem unterzeichneten Dokument
      sichtbar gemacht werden.
    </p>
    <p>
      Sie erklären sich damit einverstanden, dass eine solche Verarbeitung personenbezogener Daten durch Teamleader
      erfolgt, um die erforderlichen Sicherheitsvorkehrungen in unsere elektronische Vertragsfunktionalität zu
      integrieren.
    </p>
    <p>
      Die durch Cloudsign signierten Dokumente, einschließlich der IP-Adresse und der Uhrzeit der Signatur, werden so
      lange gespeichert, wie das Vertragsverhältnis zwischen Ihnen und Teamleader besteht. Alle Ihre persönlichen Daten
      werden von Teamleader innerhalb von sechs Monaten nach der Deaktivierung des Kundenkontos entfernt.
    </p>
    <h5>Anmerkung 1</h5>
    <p>
      Sie haben immer die Möglichkeit, sich für eine andere Art des Vertragsabschlusses zu entscheiden, z.B. in
      Papierform durch handschriftliche Unterschrift. Auch nachdem Sie diesen Nutzungsbedingungen zugestimmt haben,
      steht es Ihnen immer noch frei, den Absender aufzufordern, in Zukunft keine Dokumente mehr zur elektronischen
      Unterschrift vorzulegen, insbesondere wenn Sie als Verbraucher einen Vertrag mit der anderen Partei abschließen.
    </p>
    <h5>Anmerkung 2</h5>
    <p>
      Neben der sicheren Aufbewahrung des signierten Dokuments wird dringend empfohlen, mit der Gegenpartei ausreichend
      schriftliche Korrespondenz (z.B. per E-Mail) zu führen. Bei Streitigkeiten über den Vertrag können Sie diese
      Korrespondenz zusammen mit dem unterzeichneten Dokument einreichen, um Unklarheiten zu klären.
    </p>
    <h5>Anmerkung 3</h5>
    <p>
      Die durch Cloudsign platzierte elektronische Signatur wird höchstwahrscheinlich als „einfache elektronische
      Signatur“ im Sinne der eIDAS-Verordnung betrachtet werden. Teamleader beansprucht in keiner Weise, eine
      Funktionalität anzubieten, mit der der Benutzer eine qualifizierte oder fortgeschrittene elektronische Signatur
      setzen kann. Denken Sie daran, dass das Gesetz für einige juristische Dokumente eine qualifizierte oder
      handschriftliche Unterschrift verlangt.
    </p>
  </>
);

export default German;
